<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <el-alert :title="ruleForm.qualification_certification_status_text"
      :type="ruleForm.qualification_certification_status == 2 ? 'success' : 'warning'" show-icon :closable="false">
    </el-alert>
    <el-form :disabled="[1, 2].includes(ruleForm.qualification_certification_status)" :model="ruleForm" ref="ruleForm"
      label-width="170px" class="demo-ruleForm" :rules="rules">
      <el-form-item label="公司名称" prop="company_name">
        <el-input maxlength="50" v-model.trim="ruleForm.company_name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="taxes_distinguish_num">
        <el-input maxlength="50" v-model.trim="ruleForm.taxes_distinguish_num" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="营业执照" prop="licence_img">
        <el-upload v-show="!ruleForm.licence_img" ref="uploadFile" :action="`${MixinUploadApi}?scene=goods`"
          list-type="picture-card" :limit="1" :before-upload="beforeUpload" :on-success="handleUploadSuccess">
          <i class="el-icon-plus"></i>
          <div class="el-upload__tip" slot="tip">大小不超10MB，格式为jpg/png的图片</div>
        </el-upload>
        <div class="background-img" v-if="ruleForm.licence_img">
          <img :src="ruleForm.licence_img" alt="" />
          <div class="mantle" v-if="[1, 2].includes(ruleForm.qualification_certification_status)==false">
            <i class="el-icon-delete" @click="handleUploadRemove"></i>
            <el-button round size="mini" class="btn-change-img" @click="handleChangeImg">更换图片</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="公司地址" prop="company_address">
        <el-input maxlength="100" v-model.trim="ruleForm.company_address" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="link_name">
        <el-input maxlength="50" v-model.trim="ruleForm.link_name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="link_phone">
        <el-input v-model.trim="ruleForm.link_phone" style="width: 300px" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="bank_name">
        <el-input maxlength="50" v-model.trim="ruleForm.bank_name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="银行账号" prop="bank_number">
        <el-input maxlength="50" v-model.trim="ruleForm.bank_number" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width:112px" type="primary" @click="handleSaveShopData('ruleForm')" size="small">
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_account from "@/api/account";
export default {
  name: "qualificationCertification",
  data () {
    return {
      ruleForm: {
        qualification_certification_status_text: undefined,
        qualification_certification_status: undefined,
        company_name: undefined,
        taxes_distinguish_num: undefined,
        licence_img: undefined,
        company_address: undefined,
        link_name: undefined,
        link_phone: undefined,
        bank_name: undefined,
        bank_number: undefined,
      },
      rules: {
        company_name: [
          {
            required: true,
            message: "请选择输入公司名称",
            trigger: "blur",
          },
        ],
        taxes_distinguish_num: [
          {
            required: true,
            message: "请输入纳税人识别号",
            trigger: "blur",
          },
        ],
        licence_img: [
          {
            required: true,
            message: "请上传营业执照",
            trigger: "change",
          },
        ],
        company_address: [
          {
            required: true,
            message: "请输入公司地址",
            trigger: "blur",
          },
        ],
        link_name: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        link_phone: [
          {
            required: true,
            message: "请输入联系人电话",
            trigger: "blur",
          },
        ],
        bank_name: [
          {
            required: true,
            message: "请输入开户银行",
            trigger: "blur",
          },
        ],
        bank_number: [
          {
            required: true,
            message: "请输入银行账号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created () {
    this.getShopDetail();
  },
  methods: {
    //查询站点端检验是否需要资质认证 
    getShopDetail () {
      API_account.getShopDetail().then(res => {
        this.ruleForm = res;
        if (res.qualification_certification_required == 1) {//企业开启资质认证
          this.ruleForm.qualification_certification_status_text = this.fileterUnStatus(this.ruleForm.qualification_certification_status)
        } else {//企业未开启资质认证
          this.ruleForm.qualification_certification_status_text = this.fileterStatus(this.ruleForm.qualification_certification_status)
        }
      })
    },
    fileterStatus (status) {
      switch (status) {
        case 0:
          return "您的商城尚未完成认证，请及时提交资质认证！";
        case 1:
          return "资质认证审核中，请耐心等待！";
        case 2:
          return "资质认证已通过！";
        default:
          return "资质认证未通过！原因：" + this.ruleForm.audit_remark;
      }
    },
    fileterUnStatus (status) {
      switch (status) {
        case 0:
          return "商城管理功能暂未开启，请先完成资质认证！";
        case 1:
          return "商城管理功能暂未开启。资质认证审核中，请耐心等待！";
        case 2:
          return "资质认证已通过！";
        default:
          return "商城管理功能暂未开启。资质认证未通过！原因：" + this.ruleForm.audit_remark;
      }
    },
    //保存
    handleSaveShopData (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_account.saveShopCertification(this.ruleForm).then((res) => {
            this.getShopDetail();
            this.$message.success("认证资料已提交，请耐心等待审核");
          });
        } else {
          return false;
        }
      });
    },
    // 上传图片
    // 上传之前校验
    beforeUpload (file) {
      let result = true;
      const fileType = file.name.split(".")[1];
      const fileSize = parseFloat((file.size / 1024 / 1024).toFixed(2));
      if (fileType !== "jpg" && fileType !== "png") {
        result = false;
      }
      if (fileSize > 10) {
        result = false;
      }
      if (!result) {
        this.$message.error("请按照提示信息上传图片");
        return false;
      }
    },
    // 上传成功回调
    handleUploadSuccess (response, file, fileList) {
      this.ruleForm.licence_img = response.url;
    },
    // 删除文件
    handleUploadRemove () {
      this.ruleForm.licence_img = "";
      this.$refs.uploadFile.clearFiles();
    },
    // 更换图片
    handleChangeImg () {
      this.$refs.uploadFile.clearFiles();
      this.$refs.uploadFile.$children[1].$refs.input.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 20px;
}

/deep/ .el-form-item__content {
  .background-img {
    width: 167px;
    height: 167px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .mantle {
      width: 167px;
      height: 167px;
      display: none;
      position: absolute;
      top: 0;
      background-color: black;
      opacity: 0.5;
      border-radius: 10px;

      .el-icon-delete {
        font-size: 23px;
        position: relative;
        left: 85%;
      }

      .btn-change-img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: 15%;
        background-color: black;
        opacity: 0.6;
        color: white;
      }
    }
  }

  .background-img:hover {
    .mantle {
      display: block;
    }
  }

  .el-color-picker {
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;

    .el-color-picker__trigger {
      width: 90px;
      height: 30px;
      padding: 0;
      border: 0;
      border-radius: 5px;
    }

    .el-color-picker__color {
      border: 1px solid #cccccc;
    }

    .el-icon-arrow-down:before {
      content: "";
    }
  }
}

.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}

.shop-status-warper {
  .switch-table {
    width: 100%;
    text-align: center;
    border: 1px solid rgb(146, 147, 149);

    &:not(:last-of-type) {
      border-bottom: 0;
    }

    th {
      padding: 5px 5px;
      width: 20%;
      background-color: rgb(26, 67, 169);
      color: #fff;
      border-color: rgb(165, 185, 238);
      border-right: 1px solid rgb(165, 185, 238);
      border-bottom: 0;

      &:last-of-type {
        border-right: 0;
      }
    }

    td {
      border-right: 1px solid rgb(146, 147, 149);
      border-bottom: 0;

      &:last-of-type {
        border-right: 0;
      }
    }
  }

}
</style>
